<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    model: {},
  },
  model: {
    prop: 'model',
  },
})
export default class RadioButton extends Vue {

}
</script>

<template lang="pug">
label(:class="{ '-active': model === $attrs.value }")
  input.input(type='radio', v-bind="$attrs", :value="model", @change="$emit('input', $attrs.value)")
  slot

</template>

<style scoped lang="postcss">
@import "../../../styles";
.input {
  position: absolute;
  left: -30000px;
  visibility: hidden;
  opacity: 0;
}
</style>
