<script>
import Vue from 'vue';
import UnstyledRadio from './unstyled/UnstyledRadio';
import Component from 'vue-class-component';

@Component({
  name: 'UIRadioThumbnail',
  functional: true,
  model: {
    prop: 'model',
  },
})
export default class UIRadioThumbnail extends Vue {
  render (h, ctx) {
    return h(UnstyledRadio, {
      ...ctx.data,
      staticClass: `ui-radio-thumbnail ${ctx.data.staticClass}`,
    }, ctx.children);
  }
}
</script>

<style scoped lang="postcss">
@import "../../styles";

.ui-radio-thumbnail {
  @apply --buttonGhostTemplate;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  &:hover {
    background-position: center;
    background-size: cover;
  }
  &.-active {
    border-color: var(--colorInfoFlat);
    background-color: var(--colorInfoFlat);
    background-blend-mode: multiply;
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      z-index: 2;
      pointer-events: none;
      box-shadow: 0 0 16px var(--colorInfoFlat);
      /* box-shadow: solid 4px var(--colorInfoFlat); */
      /* outline: solid 4px var(--colorInfoFlat); */
      border-radius: var(--radius);
    }
  }
}
</style>
