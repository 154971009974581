<template>
  <div class="widget-index">
    <div class="header-bar">
      <router-link
        :to="{ name: 'widget-show', params: { widgetId: 'new' } }"
        tag="button"
      >{{ $t('New widget') }}</router-link>
    </div>

    <div class="empty f-body tc w-60 center fill-geyser fadeIn animated" v-if="noWidgets">
      <UIIcon name="no-results" class="dib mv5" style="width:8rem;" />
      <i18n tag="p" path="You don't have widgets yet. Click on {newWidgetLink} to create one.">
        <template #newWidgetLink>
          <router-link
            class="link-style"
            :to="{ name: 'widget-show', params: { widgetId: 'new' } }"
          >{{ $t('New Widget') }}</router-link>
        </template>
      </i18n>
    </div>

    <UIRowIndexLoader v-if="isLoading" />

    <div v-if="!isLoading" class="widgetgrid animated fadeIn">
      <div class="widgetcard" v-for="widget in widgets" :key="widget.id">
        <div class="title f4 nowrap dib truncate slate w">{{ widget.name }}</div>
        <UIButton tiny secondary @click="openShare(widget)" class="share">{{ $t("Share") }}</UIButton>
        <UIButton
          tiny
          icon="edit"
          :to="{ name: 'widget-show', params: { widgetId: widget.id } }"
        >{{ $t("edit") }}</UIButton>
      </div>
    </div>

    <modal
      @closeModal="share = null"
      v-if="share"
      class="share-modal"
      :title="`${$t('Share Widget')} - ${share.name}`"
    >
      <div class="main-content">
        <div class="embed">
          <h2>{{ $t('Embed Code') }}</h2>
          <p>{{ $t('Take the code below and paste it before the body closing tag of your website. You will know it is working when you see the button floating in the bottom right corner of your website.') }}</p>
          <UIInput
            :textarea="true"
            name="embedCode"
            id="embedCode"
            rows="5"
            v-model="share.embedCode"
            readonly
          />
          <UIButton :secondary="true" data-clipboard-target="#embedCode">{{ $t('Copy Embed Code') }}</UIButton>
        </div>
        <div class="link">
          <h2>{{ $t('Share Link') }}</h2>
          <p>{{ $t('This URL can be shared and used like any other URL. Feel free to share it on Twitter, Facebook, ect... You can also link to it from your website.') }}</p>
          <UIInput id="embedLink" v-model="share.embedLink" readonly />
          <UIButton :secondary="true" data-clipboard-target="#embedLink">{{ $t('Copy Share Link') }}</UIButton>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment-timezone';
import Modal from '@/core/shared/components/Modal';
import ClipboardJS from 'clipboard';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIInput from '@/core/shared/components/ui/UIInput';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIRowIndexLoader from '@/core/shared/components/ui/UIRowIndexLoader';

@Component({
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
  components: {
    Modal,
    UIButton,
    UIInput,
    UIIcon,
    UIRowIndexLoader,
  },
})
export default class widgetIndex extends Vue {
  share = null;
  clipboard = null;

  get isLoading () {
    return this.$store.state.widgets.isFetchingWidgets && this.widgets.length === 0;
  }

  get noWidgets () {
    return !this.$store.state.widgets.isFetchingWidgets && this.widgets.length === 0;
  }

  get widgets () {
    return this.$store.getters['entities/Widget'](({ location }) => location.id === this.locationId);
  }

  openShare (widget) {
    this.share = widget;
    this.$nextTick().then(() => {
      this.clipboard = new ClipboardJS('.main-content button');

      this.clipboard.on('success', e => {
        const oldText = e.trigger.innerText;
        e.trigger.innerText = this.$t('Copied');
        e.trigger.classList.add('success');
        setTimeout(() => {
          e.trigger.innerText = oldText;
          e.trigger.classList.remove('success');
        }, 3000);
      });
    });
  }

  formatExpiresAt (date) {
    return moment(date).format('LL');
  }

  created () {
    this.$store.commit('setTitle', this.$t('Widgets'));
  }

  mounted () {
    this.$store.dispatch('getLocationWidgets', this.locationId);
  }

  beforeDestroy () {
    if (this.clipboard) this.clipboard.destroy();
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.widget-index {
  @apply --container;

  & .header-bar {
    margin: var(--spacingMd) 0 var(--spacingSm) 0;
    text-align: right;

    @media (--desktop) {
      margin: var(--spacingMd) 0;
    }

    & button {
      @apply --buttonPrimary;

      display: inline-block;
      width: auto;
    }
  }

  & h2 {
    @apply --f3;
  }

  & .widgetgrid {
    display: grid;
    grid-gap: var(--spacingSm);

    & .widgetcard {
      background-color: var(--colorWhite);
      box-shadow: var(--shadowSm);
      border-radius: var(--radius);

      display: grid;
      grid-template-columns: auto 5.5rem 5.5rem;
      align-items: center;
      grid-gap: var(--spacingSm);
      padding: var(--spacingSm);

      @media (--tablet) {
        grid-gap: var(--spacingMd);
        padding: var(--spacingAltSm) var(--spacingAltMd);
      }
    }
  }

  & .share-modal .main-content {
    padding: var(--spacingMd);
    display: grid;
    grid-gap: var(--spacingMd);

    & .embed,
    & .link {
      display: grid;
      grid-gap: var(--spacingSm);

      & >>> button {
        justify-self: start;
      }
    }

    & h1 {
      @apply --f3;
    }

    & h2 {
      @apply --f4;
    }

    & p {
      @apply --f5;
    }
  }
}
</style>
